/* @import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Assistant:wght@300;400;500&family=Poppins:wght@400;500;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@500&display=swap');


*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Assistant', sans-serif;
  color:rgb(37, 85, 157);

}

html{
  scroll-behavior: smooth;
}
.section-cover{
  background-color: rgb(242, 242, 242);
}

.section-cover1{
  background-color:#fff6e7;
}

.section-cover-a{
background-color: #ecfaff;
}

.top-cover{
  border-bottom: 0.3rem solid #2ef4a1;
  background-color: #1076be;
  
}

@media screen and (max-width:1000px) {
  .top-cover {
    display: none;
  }
}

.top-section1{
  padding:0.3rem 0;
  width:90vw;
  max-width: 1170px;
  margin:0 auto;

  
}
.top-location-contact-email{
  display: flex;
  align-items: center;
  /* justify-content: center; */


}

.top-location-contact-email span{
display:inline-block;
color: rgb(255, 255, 255);
  /* color: rgb(228, 178, 85); */
  font-size: 1rem;
  /* margin-right: 0.5rem; */
}

.top-icon{
  color:rgb(247, 209, 69)!important;
  /* color:#26c9fb!important; */
  line-height: 1.1rem;
  margin-right: 0.5rem;
}

.my-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
 
}

img{
  width:100%;
}




/*Nav*/

.nav{
  padding:0.5rem 0;
 
}



.nav-center{
  width:90vw;
  margin:0 auto;
  max-width: 1170px;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;


}

  .nav-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  
}

 .logo-container{
  /* max-width: 4rem; */
  max-width: 7rem;
  padding-bottom: 0.5rem;
}

.nav-close,
.nav-toggle {
  cursor: pointer;
  font-size: 2rem;
  color:#fdde17;
  margin-top: -0.8rem;
}

.nav-toggle.hide{
  display: none;
}

.nav-close.hide{
  display: none;
}


 
.nav-links{

  list-style: none;
  position: relative;
  min-width: 15rem;
}

.nav-links li{
    height: 3rem;
  display: flex;
    align-items: center;
}

.drop-down-li{
  /* background-color: aquamarine; */
  /* padding-bottom: 2rem; */
  height: 6rem;
}


.nav-link1 {
  color:#ffffff;
  color: #fdaf17;
  color: #fdde17;
    transition: 0.5s;
    text-decoration: none;
    font-size: 1.2rem;
 margin-bottom: 1.2rem;
    cursor: pointer;
    display: inline-block;
  font-family: 'Asap', sans-serif;
  position: relative;
}

.nav-link1:hover{
  color: #ffffff;
  /* color:#1076be; */
}

.nav-link1::after {
  content: '';
  position: absolute;
  top: 1.8rem;
  width: 100%;
    width: 0%;
  background-color:#ffffff;;
  height: 0.15rem;
  left: 0;
  transition: 0.5s;
}

.nav-link1:hover::after {
  width: 0%;
  width: 100%;
}

.nav{
  
box-shadow: 0 0 0.3rem grey;
}

.links-container{
  height: 0 ;
  overflow: hidden;
  transition: 0.5s;
  
}

.drop-down-parent{
position: relative;
}




.drop-down{
 position: absolute;
z-index: 2;
background-color: white;
/* box-shadow: 0 0 0.3rem grey; */
/* border-radius: 0.3rem; */
list-style: none;
min-width: 8rem;
/* min-width: 12rem; */
/* left:2rem; */
left:7.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: rgb(37, 85, 157);
/* background-color: #25d366; */
/* padding-right: 1rem; */

top:5rem;
top:3.5rem;
  
}

@media screen and (max-width:800px){
  .drop-down{
padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  }
}

.drop-down.hide{
  display: none;
}



.drop-down li {
 background-color: rgb(37, 85, 157);;
 background-color:#2b378d;
 margin-left: -2rem;
min-width: 8rem;
/* padding-left: 1rem; */
/* border-radius: 0.3rem; */
transition: 0.5s;
border-top: 0.1rem solid rgb(255, 255, 255);
height: 2.5rem;;
width: 100%;
text-align: center;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
line-height: 1rem;
}

.drop-down li:first-child {
  
  border-top: none;
}

@media screen and (max-width:800px){
.drop-down-li{
  text-align: center;
}

  .drop-down li:last-child{
    padding-top: 1rem;
    
  }

     .drop-down li:first-child {
       padding-bottom: 0.5rem;
  
     }
}



.drop-down li:hover .drop-down-link {
  /* background-color: rgb(15, 36, 68); */
 
  color:#ea2328;

}



.drop-down-link{
    text-decoration: none;
    display: block;
    padding:0.2rem 0;
    width:100%;
    color:white;
    text-transform: uppercase;
    /* padding-right: 1rem; */
}



.drop-down-link:hover {
  color: white;
}












@media screen and (min-width:800px) {

.drop-down{
left: 8rem;
top:3rem;

}


  .nav {
    padding-bottom: 0;
background-color: #1a3c61;
  }

  .nav-toggle,
  .nav-close {
    display: none;
  }

  .nav-header {
    width: auto;
  }

  .links-container {
    height: auto !important;
 overflow: visible;
  }

  .nav-links {
    display: flex;
  }

  .nav-link1 {
    margin-left: 1.5rem;
    margin-bottom: 0;
    /* height: 5rem; */
    /* position: relative; */
  }
}

/*Fixed Nav*/

.fixed-nav{
  
position: fixed;
width:100%;
top:0;
left:0;
z-index: 3;
background-color: #1a3c61;
box-shadow: 0 0 0.3rem #182842;

}

.fixed-nav .logo-container {
  margin-top: 0.5rem;
  max-width: 5rem;
}

.fixed-nav .nav-link1{
  /* background-color: aqua; */
  margin-top: 1rem;
}

/*Home Slides*/

  /* .d-block{
    object-fit: cover;
  } */
.home-slides .d-block{
height: 100vh;
/* object-fit: cover; */
}



.slide-h3{
 background-color:#1076be; 
 text-shadow: 0 0 0.5rem black;
 max-width: 35rem;
 margin:0 auto;
 letter-spacing: 0.1rem;
 font-size: 2.5rem;
 font-weight: bold;
 border-radius: 0.4rem;
 line-height:2.5rem;
 /* border: 0.2rem solid #24d88d; */
  font-family: 'Assistant', sans-serif;
  padding-bottom: 0.25rem;
  background-color: #051c53cc;
  padding:0.5rem;
}


.slide-p{
  color:white;
  background-color:#080604cc;
  background-color: #051c53cc;
  background-color: #1075bec5;
  font-size: 1.8rem;
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 2rem;
  padding-bottom: 0.5rem;
}

.slide-p1{
  max-width: 17rem;
}

.slide-p4{
  max-width: 20rem;
}

@media screen and (max-width:900px) {
  .home-slides .d-block {
    height: auto;
  }
  .slide-h3{
    font-size: 1.2rem;
    line-height: 1.2rem;
    /* margin-top: 25rem; */
  }

  .slide-p{
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .home-slides {
    margin-top: 4.3rem;
  }

}
/* 
@media screen and (max-width:600px){
  .slide-h3{
    font-size: 1.5rem;
    line-height: 1.2rem;
  }
} */

/*Home Page*/
.bg-cover{
  background-color: #ffffff;
}

.bg-cover-a{
  background-color: #ffffff;
}
.content-container{
  width:90%;
  max-width: 1170px;
  margin:0 auto;
  padding:3rem 0;
}

.vm-container{
  max-width:1185px ;
  padding-top: 0;
}

.content-container1 {
  text-align: center;
}

.home-p{
  color:#205da8;
  font-size: 1.2rem;
  text-align: justify;
  color:#1a3c61; 
}

.home-p1{
  font-size:1.2rem ;
  margin-top: 2rem;
  font-style: italic;
}

.home-h2{
  color:#2ba612;
  text-align: center;
  /* font-family: 'Abyssinica SIL', serif; */
  font-size:3rem;
  font-family: 'Asap', sans-serif;
}

.goals-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
 padding-top:2rem;
}

.goals-container1{
  flex-direction: row-reverse;

}

.goals-container-a{
  align-items: flex-start;
  max-width: 1050px;
}

.goals-container-b{
  width:100%;
  max-width: 100%;
  justify-content: center;
  /* background-color: #1076be; */
}

.goals-slides, .goals-text{
  flex: 1 25rem;
  max-width: 30rem;
  margin: 1rem;
}

.goals-slides{
  box-shadow: 1rem -1rem 0 #f34444;
}

.goals-text1{
  max-width: 60rem;
}

.goals-text{
  background-color: white;
  border-radius: 0.5rem;
  padding:1rem;
 
    box-shadow: 0 0 0.8rem #2a3845;
     /* box-shadow: 10px 10px 8px #530a5b; */
}

 .goals-container-b .goals-text {
    flex: 1 35rem;
      max-width: 35rem;
 }

@media screen and (min-width:950px){
  .goals-container-b .goals-text{
    height:26.8rem;
    /* height: 28rem; */
  }
}

.goals-text-a{
  display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.goal{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

 
}

.goal-icon{
 color:#fdde17;
 display: inline-block;
 font-size: 1.5rem;
 margin-right: 0.5rem;
 margin-top: -0.35rem;
}
.goal-description{
  font-size: 1rem;
  color:rgb(91, 91, 91);
  color: rgb(7, 68, 104);
  text-align: justify;
  
}

.goal-description1{
    font-size: 1.2rem;
  
      text-align: left;
      color: #1a3c61;
}

.scope-image{
    box-shadow: 0 0 0.8rem #2a3845;
    border-radius: 0.5rem;
    height:20rem;
    object-fit: cover;
}
.goals-h2{
  margin-top: 2rem;
  font-size: 2.5rem;
}

.goals-carousel .d-block{
  border-radius: 0.5rem;
}

@media screen and (max-width:1140px){
.goals-carousel .d-block {
   height: 30rem;
  }
}

/*About*/
.wwa-h2{
  /* padding-top: 4rem; */
  margin-bottom: 3rem;
}

.wwa-h2-a{
margin-bottom: 2rem;
}
.about-img {
  width: 100%;
  border-radius: 50%;
  /* justify-self: center; */
  border: 0.4rem solid rgb(152, 190, 200);
}

.home-services-container {
  /* grid-template-columns: 20rem 1fr;
  gap:3rem;
  align-items: center; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  /* background-color: aquamarine; */
}

.about-img-container,
.home-services-info {
  /* flex:1 30rem; */
  margin: 1rem;
}

.about-img-container {
  flex: 1 15rem;
  max-width: 15rem;

}

.home-services-data {
  text-align: center;
}

.home-services-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: blueviolet; */
  flex: 2 20rem;
  max-width: 1190px;
}

.home-service-box {
  flex: 1 20rem;
  max-width: 20rem;
  margin: 1rem;
  margin-top: 2rem;
  background-color: #1076be;
    ;
  border-radius: 0.3rem;
  padding: 1rem;
  /* box-shadow: 0 0 0.4rem rgba(9, 9, 9, 0.666); */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 3rem;
  transition: 0.5s;
}

@media screen and (max-width:1000px) {

  .home-service-box {
    margin-top: 2rem;
  }
}

@media screen and (max-width:500px) {

  /* .about-info {
    margin-left: 0;
    margin-right: 0;
  } */
}

.home-service-box:hover {

  box-shadow: 0 0 0.5rem rgb(67, 67, 77);
}

.home-service-box:hover {
  background-color: #530a5b;;
  background-color: rgb(242, 242, 242);
}

.home-service-box p {
  color: rgb(255, 255, 255);
  font-size: 1.1rem;
}


.home-service-box:hover p {
  color: #205da8;
}

.home-service-box:hover .home-service-title {
  color: #24d88d;
}

.home-service-icon-cover {
  border: 0.15rem solid #24d88d;
  background-color: rgb(252, 234, 254);
  border-radius: 50%;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);



}

.home-service-icon {
  color: #24d88d;
  font-size: 2rem;
  line-height: 2rem;
  display: block;
  margin-top: -0.4rem;
}
/* .home-service-h2{
  font-size: 1.5rem;
} */
.home-service-cover{
  padding:3rem 0; 
}

.home-btn{
  display:inline-block;
  background-color:#fdaf17;
  border:0.15rem solid #fdaf17;
  border-radius: 0.3rem;
  text-decoration: none;
  color:white;
  transition: 0.5s;
  padding:0.3rem 0.6rem;
}

.home-btn:hover {
  border: 0.15rem solid #fdaf17;
  color:#fdaf17; 
  background-color: transparent;
}

/* .home-service-icon-cover{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width:3rem;
} */

/* .home-service-icon{
 color: rgb(226, 153, 43);
} */

.home-service-title{
  color:rgb(247, 209, 69);
}


/*Who We Are*/

.who-we-are-cover{
  background-color: #ffffff;
  padding-top:4rem;
}

/* .vm-container{
  padding-top: 1rem;
} */

.vision-mission-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.vision-mission-content-a{
  padding:3rem 0;
  max-width: 1100px;
  margin: 0 auto;
}

.mission-info{
  flex-direction: row-reverse;
}

.vision-mission-slides,.vision-mission-info{
flex: 1 30rem;
flex-wrap: wrap;
margin: 1rem;
max-width: 30rem;
}



/* .vision-mission-info{
  box-shadow: 0 0 0.8rem #2a3845;
} */

.vision-mission-slides{
  box-shadow: 0.5rem -0.5rem 0 #fdaf17;
  box-shadow: 1rem -1rem 0 #f34444;
}

.title-h3{
  color: #2ba612;
  text-align: center;
  font-family: 'Asap', sans-serif;
  font-size: 2.5rem;
}

.title-h3-a{
  margin-top: 1rem;
}

.title-h3-b {
  margin-bottom: 0rem;
}

.title-h3-c{
  margin: 1rem 0;
}

.vision-mission-info p{
  text-align: center;
  color: #1a3c61;
    font-size: 1.2rem;
}
.about-intro-p {
  text-align:center;
  color: #205da8;
  font-style: italic;
  font-size: 1.3rem;
  max-width: 60rem;
  margin:0 auto;
  padding: 0 1rem;
  padding-top: 2rem;

}


/* .vision-mission-info{
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
} */

.vision-mission-info1 ul{
    color: #1a3c61;
    font-size: 1.2rem;
   max-width:8rem;
    margin:0 auto;
}


.vm-line{
  background-color: #fdde17;;
  margin:0 auto;
  max-width: 5rem;
  height:0.2rem;
  margin-bottom: 1rem;
}

.core-values-container{
  max-width: 800px;
}

.core-values-h2{
  margin-bottom: 3rem;
}

.accordion-p{
  color:rgb(121, 121, 121);
  text-align: center;
  font-size: 1.1rem;
}

.accordion-h{
  font-size: 1.3rem;
    color: rgb(37, 85, 157);
  
 
}

/*Team*/

.team-cover{
  background-color:#fffbf2;
}

/* .team-cover1{
  text-align: center;
} */

.table-container{
  overflow-x: auto;
  max-width: 1200px;
  /* background-color: #103544; */
  /* width:100%;
  max-width: 100%; */
  /* background-color: #0e315c; */
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 0.4rem grey;
  /* margin-left:0.5rem;
  margin-right: 0.5rem; */
  max-width: 60rem;
  margin:0 auto;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #ebf3f7;
  /* background-color: #f3d3a8; */
}
th{
  color:white;
  background-color:#182842;
 
}

/* .team-h2{
  margin-bottom: 3rem;
} */

.team-h2a{
  margin-top: 4rem;
}

td{
  color:#2a3845;
}

.team-table{
  margin:3rem auto;
  margin-bottom: 1rem;
}

.date-col{
  min-width: 7rem;
}
/* .team-table tr{
  text-transform:uppercase;
} */

/* .team-table1 tr {
  text-transform:none;
} */
/*Services*/

.services-cover{
      background-color: #ecfaff;
      padding-top: 3rem;
}

.services-container{
  padding-top: 1rem;
}

.service-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color:#fff6e7;
  background-color: #e9f3f7;
  box-shadow: 0 0 0.5rem #aeaeae;
  border-radius: 0.5rem;
  margin: 2rem 0;
  padding:2rem 0;
}

.odd-service-container{
  flex-direction: row-reverse;
}

.service-title, .service-slides{
  flex:1 30rem;
  max-width: 30rem;
  margin:1rem;
}

.service-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-title li{
  color: #205da8;
  font-size: 1.2rem;
}

.bg-cover-a li{
color: #2d3192;
font-size: 1.2rem;
}

.service-title p{
  color: #205da8;
    font-size: 1.2rem;
    text-align: center;
}


.service-h3{
  color:#24d88d;
  font-size: 1.5rem;
  /* margin-bottom: 0; */
}

.services-slides .d-block{
  height: 25rem;
  object-fit: cover;
}

/* .services-h2{
  padding-top: 4rem;
} */

/*Clients*/

.clients-cover{
  background-color: #ecfaff;
    padding-top: 3rem;
      padding-bottom: 3rem;
}

.clients{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  margin-top: 2rem;
  padding-top: 0;
  
}

.client{
  flex:1 20rem;
    flex: 1 15rem;
  margin:1rem;
  max-width: 25rem;
  max-width: 15rem;
  height: 15rem;
  
}

.client p{
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 0;
}

.client-image{
  height: 100%;
  object-fit: contain;
}

.clients-para{
  margin:0 auto;
  color:rgb(201, 44, 196);
  max-width: 50rem;
  padding:0 1rem;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;  
}


/*Gallery*/


.gallery-cover{
background-color: #ecfaff;
}

.gallery-h2{
  padding-top: 3rem;
  margin-bottom: -1rem;
}



.gallery-image{
  width:100%;
  height:100%;
    object-fit: cover;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.6rem rgb(73, 73, 73);  
}

/* .tools-slides {
  border-radius: 0.3rem;
  box-shadow: 0 0 0.6rem rgb(73, 73, 73);
} */

.tools-slides .d-block{
  height: 15rem;
  /* border-radius: 0.3rem; */
    /* box-shadow: 0 0 0.6rem rgb(73, 73, 73); */
}



.gallery{
 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-bottom: 2rem;
  
}

.gallery-content{
  
  flex:1 25rem; 
  margin:1rem;
  margin-bottom: 9rem;
  max-width: 25rem;
  height:25rem; 
}

@media screen and (max-width:600px){
  .gallery-content {
      margin-bottom: 10rem;
    }
}

.gallery-content p{
 
  margin-top: 0.5rem;
  text-align: center;
   color: #205da8;
    font-size: 1.1rem;
}

/*Contact*/

.contact-cover{
background-color: #ecfaff;
padding-top: 2rem;
}

form {
display: flex;
align-items: flex-start;
flex-direction: column;
width: 100%;
font-size: 16px;

}

     input {
       width: 100%;
       height: 35px;
       padding: 7px;
       outline: none;
       border-radius: 5px;
       border: 1px solid rgb(220, 220, 220);
       box-shadow: 0 0 0.2rem grey;
     }

     input:focus {
       border: 2px solid #205da8;
     }

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0 0 0.2rem grey;
}

textarea:focus {
  border: 2px solid rgb(37, 85, 157);
}

label {
  margin-top: 1rem;
color: #205da8;
  
}

 input[type="submit"] {
   margin-top: 2rem;
   cursor: pointer;
   background:#205da8;
   color: white;
   border: none;
   transition: 0.5s;
 }

 input[type="submit"]:hover {
   background:#0e315c;
 }

 .contact-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* justify-content: flex-end; */
  align-items: center;
  padding-top: 0;


 }

 .contact-slides,.form-cover{
  flex:1 30rem;
  max-width: 30rem;
  margin:1rem;
 }

 .contact-slides{
  box-shadow: 1rem -1rem 0 #f34444;
 }

 .contact-slides-cover .d-block{
height: 25rem;
 }

 /*WhatsApp Icon*/

    /* for desktop */
    .whatsapp_float {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 40px;
      left: 40px;

      background-color: #25d366;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 2px 2px 3px #999;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
    }
  
    .whatsapp_float:hover{
      background-color: #167539;
    }
    .whatsapp-icon {
      color:white;
    }

    .whatsapp-icon:hover{
      color:white;
    }
  
    /* for mobile */
    @media screen and (max-width: 767px) {
      .whatsapp-icon {
        /* margin-top: 10px; */
      }
  
      /* .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        left: 10px;
        font-size: 22px;
      } */
    }

    /* .home-quote{
      padding:3rem 0;
      background: linear-gradient(rgb(39, 39, 50), rgb(36, 36, 48)),url('./images/31.jpg');
    } */

    .quote-content{
      width:90vw;
      max-width: 50rem;
      margin:0 auto;
    }

    .home-quote p{
      color:white;
      font-size: 1.5rem;
      text-align: center;
      font-style: italic;
      position: relative;
    }

    .quote-cover{
      position: absolute;
      color:#24d88d;
      font-size: 2.5rem;
      top:-4rem;
    }

    .quote-h2{
      font-size:4rem;
      text-align: center;
      color: rgb(247, 209, 69);
      color: rgb(255, 255, 255);
      font-family: 'Asap', sans-serif; 
    }

    .pledge-line{
      width:7rem;
      height: 0.25rem;
      background-color:#24d88d;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    /*Back to top*/
    

    .back-top-cover{
      border: none;
      cursor: pointer;
      width:3.5rem;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      right:2rem;
      bottom:2rem;
      background-color: #f34444;
      border-radius: 50%;
      box-shadow: 0 0 0.3rem grey;
      z-index:3;
      transition: 0.5s;
      animation: top 2s linear infinite;
    }

  .back-top-cover:hover{
background-color: #ff8080;
  }

    @keyframes top{
0%{
transform: scale(1);
}

50%{
transform: scale(1.2);
}

100%{
transform: scale(1);
}



    }

    .top-arrow{
      margin-top: -0.5rem;
      color:white;
      font-size: 2rem;
      display: inline-block;
      line-height: 2rem;
    }

    .hide{
      display: none;
    }

    /*New Core Values*/
    .core-values-container1{
      width:100%;
      margin-top: 2rem;
    }

    .core-value{
      box-shadow: 0 0 0.2rem grey;
      border-radius: 0.3rem;
      padding:0.5rem;
      margin:1rem;
      
    }

    .core-value h3 {
      font-size: 1.2rem;
      color:#205da8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    .core-value p{
      border-top: 0.13rem solid #24d88d;
      padding-top: 1rem;
      
    }

    .core-value-icons{
      font-size: 2rem;
      border-radius: 50%;
      background-color: rgb(247, 209, 69);
      width:2rem;
      height:2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-top: 0.5rem;
    }

    .core-value-icons span{
      display: inline-block;
      margin-top: -0.5rem;
    }

    /* .core-values{
      background-color: #50a820;
      max-width: 5rem;
      margin: 0 auto;
    } */

    /*Footer*/

    
    .footer-content{
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width:90%;
      margin:0 auto;
      
    }

    .footer-inner-content{
      
      flex:1 15rem;
      max-width: 20rem;
      margin:1rem;
      display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }

   

    .footer-cover{
      color:white;
      border-top: 0.3rem solid #fdde17;
      padding-bottom: 0;
      padding-top: 3rem;
      
    }

    .footer-link a{
      text-decoration: none;
      color:white;
      transition: 0.5s;
      display: inline-block;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

        .footer-link a:hover {
         color:#ff8080;
        }

    .footer-link span{
      display: inline-block;
    }


    .footer-icons{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-icons span{
  font-size: 2rem;
  margin:0.3rem;
  cursor: pointer;
  color:#26c9fb;
  transition: 0.5s;
    }

        .footer-icons span:hover {
        
          color:#ea2328;
         
        }

.footer-inner-content h3{
  color:rgb(207, 223, 239);
  color:#26c9fb;
  color:#fdde17;
  font-size: 1.5rem;
}

.footer-a{
  margin-top: 0.3rem;
}

.footer-bottom{
  text-align: center;
      background-color:#1a3c61;;
    padding: 1rem 0;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    border-top: 0.3rem solid #fdde17;
    margin-top: 2rem;
}

.footer-bottom p {
  font-size: 0.9rem;
}

.footer-link-a {
  text-decoration: none;
  color:rgb(239, 223, 141);
  transition: 0.5s;
}

.footer-link-a:hover {
  
  color: rgb(148, 200, 224);
}

.footer-vm{
  text-align: justify;
 
}

.buildings .d-block{
height: 25rem;
border-radius:0;
}

/* .certifications-cover{
  margin:0 auto ;
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
align-items: center;
  
}
.all-certs{
  background-color: aqua;
  margin: 0 auto;
  max-width: 1100px;
  
}
.certification-image{
  display: inline-block;
  flex:1 50%;
  max-width: 25rem;
} */

.certification-image{
  width:100%;
}

.cert-cover{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin:0 auto; 
  padding:1rem 0;
  
}


.cert-cover div{
  flex:1 40rem;
  max-width: 40rem;
  margin:1rem;
  box-shadow: 0 0 0.8rem #2a3845;
}

.email-link{
  text-align: none;
  transition: 0.5s;
  color:white;
  text-decoration: none;

}

.email-link:hover {
  color:#ff8080;
}



.success-box {
  background-color: rgb(186, 218, 186);
  border: 0.15rem solid rgb(79, 172, 79);
  padding: 0.3rem 1rem;
  color: rgb(79, 172, 79);
  margin: 0 auto;
  margin-top: 1rem;
}

.error-box {
  background-color: rgb(244, 233, 233);
  border: 0.2rem solid rgb(149, 45, 45);
  padding: 0.5rem 1rem;
  color:rgba(24, 40, 66, 0.92);
  color: rgb(149, 45, 45);
  margin: 0 auto;
  margin-top: 1rem;
}

.goals-list{
  text-align: center;
}

/* .goals-list1-a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #167539;
} */

.line1{
  background-color:#fdde17;
  width:10rem;
  height: 0.3rem;
  margin:0 auto;
  margin-bottom: 2rem;
  position: relative;
}

.line2 {
  background-color: rgb(226, 153, 43);
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.table-container p{
  text-align: justify;
  font-size: 1.1rem;
  color:rgb(71, 71, 71);
}

/* .projects-p{
  font-size: 1.5rem;
  text-align: center!important;
  margin: 0 auto!important;
} */

.social-link{
  color: #f34444;
  transition: 0.5s;
}

.social-link:hover{
color:#ff8080;
}

.vision-slides .d-block, .mission-slides .d-block{
height:25rem;
object-fit: cover;
}

/* .founders-image{
  max-width: 15rem;
  margin:0 auto;
} */

 .subscribe-btn{
    cursor: pointer;
    background: #205da8;
    color: white;
    border: none;
    transition: 0.5s;
    width:100%;
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    border:0.15rem solid white;
      padding: 0.3rem 0;
      display: inline-block;
      text-decoration: none;
}
.subscribe-btn:hover{
  background: #0e315c;
  color:white;
}

.newsletters-h3{
  margin-top: 0.5rem;
} 

/* .medical-center{
  height:100%!important;
 display: inline-block;
 object-fit: cover!important;
}  */

.vision-mission-info-a {
  max-width: 100%;
}

.home-ul{
  color: #205da8;
  color: #2d3192;
  font-size: 1.2rem;   
}

.benefits-cover{
  background-color: #efefef;
  padding-top: 2rem;
}


/* OUR PRODUCT RANGE */

.catergories-container {
  width: 90%;
  margin: 0 auto;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center; */
}

.category {
  /* flex: 1 30rem; */
  /* max-width:34rem; */
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  transition: 0.5s;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem #263923;
  padding: 0.5rem;
}

.category:hover {
  box-shadow: none;
}

@media screen and (max-width:640px) {
  .category {
    justify-content: center;
  }
}

.category-image,
.category-content {
  /* flex: 1 16rem; */
  flex: 1 33rem;
  /* max-width: 16rem; */
  max-width: 33rem;
  margin:1rem;
}

/* .category-content{

} */


.category-image {
  height: 15rem;
  height: 25rem;
}





.category-photo {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}

.category-h3 {
  color: #24d88d;
  font-size: 1.5rem;
  /* font-size: 3rem; */
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-family: 'Asap', sans-serif;
}

.category-h3-a{
  margin-bottom: 2rem;
  padding:0 1rem;
  font-style: italic;
}

.category-h3-b{
  font-family: 'Asap', sans-serif;
  font-size:2.5rem;
}

.category-h3-c{
  color:white;
}

.category-p {
  text-align: center;
  color: #205da8;
  font-size: 1.2rem;
}

.category-link {
  display: inline-block;
  color: #103544;
  color: #01184e;
  border: 0.2rem solid #103544;
  border: 0.2rem solid #01184e;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: 500;
  transition: 0.5s;
}

.category-link:hover {

  color: #ffffff;
  border: 0.2rem solid #01184e;
  background-color: #01184e;
}

.item-image-a {
  object-fit: contain;
}

@media screen and (min-width:890px) {
  .item-image {
    height: 22rem;
  }
}

.category-photo {
  object-fit: cover;
  border-radius: 0.5rem;
}

.section-cover-b{
      background-color: #ecfaff;
      padding: 3rem 0;
      padding-top: 4rem;
}

/* Programmes */

.services-container-new {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.single-service-new{
  flex:1 23rem;
  max-width: 23rem;
  margin:1rem;
  box-shadow: 0 0 0.5rem #1a3c61;
  padding:0.5rem;
}

.skill-h3{
  color: #2ba612;
  text-align: center;
  margin-top: 0.5rem;
}

.programmes-p{
color:#1a3c61!important;
}

.clear-image{
  object-fit: contain!important;
}


